// TODO[@bust] License

@font-face {
  font-family: 'TWK Lausanne';
  font-display: auto;
  src: url('/assets/fonts/TWKLausanne-300-export/TWKLausanne-300.woff2') format('woff2'),
       url('/assets/fonts/TWKLausanne-300-export/TWKLausanne-300.woff') format('woff'),
       url('/assets/fonts/TWKLausanne-300-export/TWKLausanne-300.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'TWK Lausanne';
  font-display: auto;
  src: url('/assets/fonts/TWKLausanne-300Italic-export/TWKLausanne-300Italic.woff2') format('woff2'),
       url('/assets/fonts/TWKLausanne-300Italic-export/TWKLausanne-300Italic.woff') format('woff'),
       url('/assets/fonts/TWKLausanne-300Italic-export/TWKLausanne-300Italic.otf') format('opentype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'TWK Lausanne';
  font-display: auto;
  src: url('/assets/fonts/TWKLausanne-400-export/TWKLausanne-400.woff2') format('woff2'),
       url('/assets/fonts/TWKLausanne-400-export/TWKLausanne-400.woff') format('woff'),
       url('/assets/fonts/TWKLausanne-400-export/TWKLausanne-400.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'TWK Lausanne';
  font-display: auto;
  src: url('/assets/fonts/TWKLausanne-400Italic-export/TWKLausanne-400Italic.woff2') format('woff2'),
       url('/assets/fonts/TWKLausanne-400Italic-export/TWKLausanne-400Italic.woff') format('woff'),
       url('/assets/fonts/TWKLausanne-400Italic-export/TWKLausanne-400Italic.otf') format('opentype');
  font-style: italic;
  font-weight: 400;
}
