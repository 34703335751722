body[data-template='person'] {
  .location,
  .person__contact {
    margin-top: var(--gutter);
    margin-bottom: var(--gutter) !important;
  }

  .person__contact {
    padding-bottom: var(--gutter);
  }
}
