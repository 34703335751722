.logo {
  display: flex;
  gap: var(--gutter--small);
  align-items: center;

  .js-connections {}

  svg {
    height: 100%;
    width: auto;
    overflow: visible;
  }
}
