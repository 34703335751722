@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(1); }
  10% { transform: translate(-50%, -50%) scale(1.1); }
  20% { transform: translate(-50%, -50%) scale(1); }
  30% { transform: translate(-50%, -50%) scale(1.2); }
  50% { transform: translate(-50%, -50%) scale(1); }
  100% { transform: translate(-50%, -50%) scale(1); }
}

.movie {
  aspect-ratio: 16/9;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--gutter);
  gap: var(--gutter--small);

  .tags {
    padding: 0 var(--gutter);
  }

  &[data-playable]::after {
    pointer-events: none;
    position: absolute;
    content: '';
    background-image:
      url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="white" width="100%25" height="100%25"%3E%3Cpath d="M13.6,7.2l-10-7C2.9-0.3,2,0.2,2,1v14c0,0.8,0.9,1.3,1.6,0.8l10-7C14.1,8.4,14.1,7.6,13.6,7.2z"%3E%3C/path%3E%3C/svg%3E'),
      radial-gradient(circle at 50%, rgba(0, 0, 0, 0.3), transparent 80%)
    ;
    width: 5rem;
    height: 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[data-playable]:hover::after {
    animation: pulse 1.6s infinite;
  }

  &[data-size=small] {
    --background-gradient--top: 20%;
    width: col(2);

    .label {
      display: none;
    }

    .card__title {
      @include font('heading--small');
    }
  }

  &[data-size=medium] {
    --background-gradient--top: 40%;
    width: col(3);

    .card__title {
      @include font('heading--medium');
    }
  }

  &[data-size=large], &:not([data-size]) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .card__footer {
      @include invisible-scroll;
      padding-right: var(--gutter);
    }

    .card__title {
      @include font('heading--large');
      margin-bottom: -0.15em;
    }
  }
}
