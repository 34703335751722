body[data-template='home'] {
  main > .video {
    overflow: hidden;
    border-radius: var(--round);
    .iframe-container {
      aspect-ratio: 16/9;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .block-events .cards__content {
    @include screen('mobile') {
      flex-direction: column;
    }
  }
}
