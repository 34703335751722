#toggleSearch { display: none; }
#toggleSearch:not(:checked) + .search {
  @include no-select;
  pointer-events: none;
  opacity: 0;
}

.search {
  @include easing(var(--fast), opacity);
  @include font('heading--medium');
  @include inset(0, fixed);

  z-index: 1000;
  background: var(--black);
  color: var(--white);
  display: grid;
  place-items: center;

  & > div {
    width: col(4);
  }

  form {
    position: relative;
    margin-bottom: calc(var(--gutter) * 2);
  }

  label[for=toggleSearch] {
    @include easing(var(--fast), color);
    @include hitbox(1rem, false);
    position: absolute;
    top: var(--gutter--small);
    right: var(--gutter);
    cursor: pointer;

    &:hover {
      color: var(--accent);
    }
  }

  input, button {
    @include reset-button;
  }

  input[type=search] {
    text-transform: uppercase;
    border-bottom: 1px solid var(--dim--text);
    min-width: 100%;
    width: 100%;
    padding: var(--gutter--small) 0;
    padding-right: 3rem;

    &::placeholder {
      @include easing(var(--fast), opacity);
      color: var(--white);
      opacity: 0.3;
    }
  }

  button {
    @include hitbox(1rem);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg { @include easing(var(--fast), fill); }
    &:hover svg { fill: var(--accent); }
  }

  &__footer {
    @include font('menu');
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    & > div {
      width: 70%;
    }
  }

  .tags {
    margin-top: var(--gutter--small);
    flex-wrap: wrap;
  }

  .tag {
    --background: var(--white);
    --color: var(--black);
  }

  // Search in mobile menu
  @include screen('mobile') {
    position: unset !important;
    opacity: unset !important;
    pointer-events: unset !important;
    color: var(--black);
    background: transparent;
    display: block;

    input[type=search] {
      border-top: 1px solid var(--black);
      border-bottom: 1px solid var(--black);
      padding: var(--gutter) 0;

      &::placeholder {
        color: var(--black);
      }
    }

    & > div {
      width: 100%;
      max-width: unset;
    }

    label[for=toggleSearch],
    form ~ * {
      display: none;
    }

    .tag {
      --background: var(--black);
      --color: var(--white);
    }
  }
}
