body[data-template='debug/logo'] {
  main {
    display: grid;
    place-items: center;
  }

  .logo { height: 10rem; }
  .logo.is-real-size { height: 3.9rem; }

  &.x-ray {
    background: rgb(54, 89, 236);

    .logo * {
      pointer-events: bounding-box;
      &:hover {
        outline: 1px solid rgba(255, 255, 255, 0.5);
      }
    }

    .logo svg {
      fill: none;
      stroke: white;
      stroke-width: 1px;

      &, * {
        vector-effect: non-scaling-stroke;
      }
    }
  }

  &.invert {
    background: black;

    .logo svg {
      fill: white;
    }
  }

  .tp-dfwv {
    --tp-base-background-color: hsla(0, 0%, 20%, 0.90);
    --tp-base-shadow-color: hsla(0, 0%, 0%, 0.2);
    --tp-button-background-color: hsla(0, 0%, 100%, 1.00);
    --tp-button-background-color-active: hsla(0, 0%, 85%, 1.00);
    --tp-button-background-color-focus: hsla(0, 0%, 90%, 1.00);
    --tp-button-background-color-hover: hsla(0, 0%, 95%, 1.00);
    --tp-button-foreground-color: hsla(230, 20%, 11%, 1.00);
    --tp-container-background-color: hsla(0, 0%, 0%, 0.20);
    --tp-container-background-color-active: hsla(0, 0%, 0%, 0.35);
    --tp-container-background-color-focus: hsla(0, 0%, 0%, 0.30);
    --tp-container-background-color-hover: hsla(0, 0%, 0%, 0.25);
    --tp-container-foreground-color: hsla(0, 0%, 100%, 0.90);
    --tp-groove-foreground-color: hsla(0, 0%, 0%, 0.50);
    --tp-input-background-color: hsla(0, 0%, 0%, 0.50);
    --tp-input-background-color-active: hsla(0, 0%, 0%, 0.65);
    --tp-input-background-color-focus: hsla(0, 0%, 0%, 0.60);
    --tp-input-background-color-hover: hsla(0, 0%, 0%, 0.55);
    --tp-input-foreground-color: hsla(0, 0%, 100%, 0.90);
    --tp-label-foreground-color: hsla(0, 0%, 100%, 0.90);
    --tp-monitor-background-color: hsla(0, 0%, 0%, 0.50);
    --tp-monitor-foreground-color: hsla(0, 0%, 100%, 0.75);

    &:hover {
      --tp-base-background-color: hsla(0, 0%, 20%, 1);
    }

    .tp-rotv {
      @include easing(var(--fast), background-color);
    }

    .tp-logger .tp-lblv_l,
    .tp-sldtxtv_t,
    .tp-rsltxtv_t,
    .tp-fldv_i {
      display: none;
    }

    .tp-logger .tp-lblv_v {
      width: 100%;
    }

    .tp-fldv_c {
      padding-left: unset;
    }

    .tp-ckbv_w {
      margin-left: auto;
      margin-right: 6px;
    }
  }
}
