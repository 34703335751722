.article {
  $max-width: 70ch;

  &__cover {
    aspect-ratio: 16/9;
    margin-bottom: calc(var(--gutter) * 3);
    background: var(--black);

    &:empty {
      display: none;
    }

    figure {
      height: 100%;
    }

    img {
      border-radius: var(--round);
    }
  }

  &.can-play &__cover {
    @include screens(('small', 'medium', 'large')) {
      aspect-ratio: unset;
      height: 75vh;
    }
  }

  &__header {
    max-width: Min(col(4), $max-width);
    margin: calc(var(--gutter) * 2) auto calc(var(--gutter) * 3);

    @include screens(('small', 'mobile')) {
      max-width: col(4);
    }

    .label {
      margin-bottom: var(--gutter--small);
    }

    .tags {
      width: fit-content;
    }
  }

  &__title {
    @include font('heading--large');
  }

  &__subtitle {
    @include font('heading--small');
    margin: var(--gutter--small) 0;
  }

  &__content:has(.article__aside) {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: col(2) col(3);

    @include screens(('small', 'mobile')) {
      grid-template-columns: 1fr;
    }
  }

  &__text,
  &__footer {
    width: 100%;

    margin: 0 auto;
    margin-bottom: calc(var(--gutter) * 3);

    @include screens(('small', 'mobile')) {
      max-width: unset;
    }
  }

  &__text {
    @include clearfix;
    max-width: Min(col(4), $max-width);
  }

  &__footer {
    border-top: var(--border);
    padding-top: var(--gutter--small);

    &:empty {
      display: none;
    }

    & > .cards + .cards,
    & > .card + .cards {
      margin-top: var(--gutter);
    }
  }
}
