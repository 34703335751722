.location {
  display: block;
  padding: var(--gutter);
  background: var(--dim--fill);
  border-radius: var(--round);

  &__name {
    @include underline;
    font-weight: 400;
  }
}
