html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font;
  font-family: 'TWK Lausanne';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  min-height: 100vh;
  background: var(--white);
  color: var(--black);

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
}

main {
  flex: 1;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 var(--gutter);
  overflow-x: hidden;

  & > *:not(:first-child) {
    margin-top: var(--gutter);
  }
}

mark,
::selection  {
  background: var(--accent);
  color: var(--white);
  text-shadow: none;
}

code, pre {
  user-select: all;
}

details {
  padding: var(--gutter);
  border: var(--border);
  border-radius: var(--round--small);

  &[open] {}

  summary {
    @include underline;
    @include font('label');
    cursor: pointer;
    margin: calc(-1 * var(--gutter));
    padding: var(--gutter--small);
  }

  &[open] summary {
    color: var(--accent);
    border-bottom: var(--border);
    margin-bottom: var(--gutter);
  }

  table {
    width: calc(100% + var(--gutter) * 2);
    margin: calc(-1 * var(--gutter));
    table-layout: fixed;
    td { padding: var(--gutter); }
    tr + tr { border-top: var(--border); }
    td + td { border-left: var(--border); }
  }
}

code {
  font-size: 72%;
  vertical-align: 0.1em;
  padding: 0.15em 0.4em 0.25em;
  border-radius: 3px;
  background: var(--dim--fill);
}

pre {
  background: var(--dim--fill);
  padding: var(--gutter);
  border-radius: var(--round);
  overflow-x: scroll;
  overflow-y: visible;
}

img, canvas, svg, iframe, object, video {
  @include no-select;

  display: block;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

img {
  width: 100%;
  height: auto;
}

figure img {
  object-position: var(--focus-x, 50%) var(--focus-y, 50%);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

[data-lazyload]{
  @include easing(1s, opacity);

  &[data-loaded=true] {}
  &:not([data-decoded=true]) {
    opacity: 0;
  }
}

button, input, a {
  accent-color: var(--accent);
}

a {
  @include underline;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: inherit;

  p & {
    text-decoration-color: currentColor;
  }
}

a.tag {
  @include font('tag');
  @include easing(var(--fast), (background, color, transform));
  @include no-select;

  --color: var(--white);
  --background: var(--black);

  display: inline;
  border: 0;
  text-decoration: none;

  background: var(--background);
  color: var(--color);
  border-radius: var(--round--small);
  padding: .7rem 1rem .5rem 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: fit-content;


  p & {
    --color: var(--black);
    --background: var(--dim--fill);
  }

  &.is-active,
  &[href]:hover,
  &[href]:focus {
    --background: var(--accent);
    --color: var(--white);
  }

  svg {
    display: none;
  }

  .filters &.is-active {
    display: inline-block;
    position: relative;
    padding-right: calc(0.5rem + 1.4rem + 0.5rem);

    svg {
      display: block;
      position: absolute;
      top: 0.3rem;
      right: 0.5rem;

      fill: var(--white);

      height: 1.6rem;
      width: auto;
    }
  }
}

b, strong {
  font-weight: 500;
}

.flexgroup {
  display: flex;
  flex-direction: inherit;
  gap: inherit;
  align-items: inherit;
}

[data-label]::before {
  content: attr(data-label);
}

.label,
[data-label]::before {
  @include no-select;
  @include font('label');
  text-transform: uppercase;
  color: var(--dim--text);
}

.block-label {
  margin-bottom: -4px;
}

.block-search {
  @include easing(var(--fast), (background, color));
  @include font('heading--large');
  border-radius: var(--round);
  background: var(--accent);
  padding: calc(var(--gutter) * 1.5);

  &:has(input:focus) {
    background: var(--black);
    color: var(--white);
  }

  form {
    position: relative;
  }

  input, button {
    @include reset-button;
  }

  input[type='search'] {
    min-width: 100%;
    width: 100%;
    padding-right: 2em;

    &::placeholder {
      @include easing(var(--fast), opacity);
      text-transform: lowercase;
      color: currentColor;
    }

    &:focus::placeholder {
      opacity: 0.3;
    }
  }

  button {
    @include hitbox(1rem);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }
}

.block-announcement {
  @include font('heading--large');
  border-radius: var(--round);
  background: var(--accent);
  padding: calc(var(--gutter) * 1.5);

  &__text {
    padding-right: calc(var(--gutter) * 3);
    // Artificially set descent-override to 0%
    margin-bottom: -0.23em;

    @include screen('mobile') {
      padding-right: 0;
    }
  }

  &__footer {
    @include font('heading--medium');
    margin-top: calc(var(--gutter) * 10);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}
