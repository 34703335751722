.card {
  @include no-select;
  --background-gradient--top: 60%;

  color: var(--black);
  background: var(--dim--fill);
  border-radius: var(--round);

  position: relative;
  isolation: isolate;
  overflow: hidden;

  flex: none;

  &[data-background] {
    color: var(--white);
    background: transparent;
  }

  .label {
    color: inherit;
  }

  &__link {
    @include inset;
  }

  &__background, figure.has-transition {
    @include easing(var(--slow), transform, true);
    position: relative;
    z-index: -1;
    transform-origin: var(--focus-x, 50%) var(--focus-y, 50%);
    transform: scale(1.01);
    height: 100%;
  }

  &__background {
    @include inset;
    background: var(--dim--fill);
    &::after {
      @include inset;
      @include easing(var(--fast), opacity);
      @include smooth-gradient(to top, (0, 0, 0));
      content: '';
      top: var(--background-gradient--top);
      opacity: 0.7;
    }

    &:has(img[data-lazyload]:not([data-decoded='true']))::after {
      opacity: 0;
    }
  }

  &__link:hover + &__background,
  &__link:hover ~ figure.has-transition,
  &__link:hover ~ &__content figure.has-transition,
  &__link:hover ~ &__footer figure.has-transition {
    transform: scale(1.05) rotate(0.1deg);
  }


  &__content {
    padding: var(--gutter) var(--gutter) 0 var(--gutter);
  }

  &__content &__title {
    @include underline;
  }

  // Underline title when hovering card link
  &__link:hover ~ &__content &__title {
    text-decoration-color: currentColor;
  }

  // Move clickable elements on top of &__link
  .card, .tags {
    position: relative;
    z-index: 1;
  }
}
