body[data-template='debug/suivi-des-films'] {
  .pages-index {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    tbody {
      td {
        @include font('label');
      }

      td.title {
        width: unset;
      }
    }

    .tags.is-empty {
      display: block;

      &::after {
        content: '—';
        color: var(--dim--text);
      }
    }
  }
}
