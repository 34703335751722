.commentary {
  @include no-select(false);

  .card {
    --round: var(--round--small);
    width: unset !important;
  }

  &__text {
    padding-top: var(--gutter);
    p:last-child {
      margin-bottom: 0;
    }
  }

  & > .card__footer {
    padding: var(--gutter);
    .tags {
      justify-content: flex-end;
    }
  }
}
