.contribution {
  --background-gradient--top: 10%;

  padding-bottom: var(--gutter);

  @include screen('mobile') {
    --background-gradient--top: -50%;
  }

  &[data-background] {
    padding-top: 20rem;

    @include screen('mobile') {
      padding-top: var(--gutter);
    }
  }

  .card__title {
    @include font('heading--large');
    margin-top: 0.1em;
    text-underline-offset: 0.1em;
  }

  &__excerpt {
    max-width: 60ch;
    margin-top: var(--gutter);
  }

  .cards {
    --button-offset: calc(var(--gutter) / 2);
    --cards-width: calc(100% / var(--cols) - (var(--gutter) / (var(--cols) + 1)) * (var(--cols) + 1));
    --round: var(--round--small);

    margin-top: calc(var(--gutter) * 2);

    @include screen('small') {
      --cols: 2;
    }

    @include screen('mobile') {
      --cards-width: calc(100% - var(--gutter));
    }

    .cards__content {
      scroll-padding-left: var(--gutter);
      padding-right: var(--gutter);
    }

    .card {
      @include screen('mobile') {
        margin-left: var(--gutter);
      }

      &:first-child {
        margin-left: var(--gutter);
      }
    }
  }
}
