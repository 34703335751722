.person {
  max-width: col(3);
  min-height: col(1);
  display: flex;
  align-items: stretch;

  @include screen('mobile') {
    flex-direction: column;
  }

  .card__footer {
    order: -1;
    width: col(1);
    overflow: hidden;

    border: var(--border);
    border-top-left-radius: var(--round);
    border-bottom-left-radius: var(--round);

    @include screen('mobile') {
      width: 100%;
      border-top-right-radius: var(--round);
      border-bottom-left-radius: 0;
    }

    [data-contain] img {
      object-fit: contain;
    }
  }

  &:not(.has-cover) .card__footer {
    display: none;
  }

  .card__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--gutter);

    padding-bottom: var(--gutter);
  }

  &:not(.has-cover) .card__content {
    margin-left: unset;
  }

  .card__title {
    @include font('heading--small');
  }
}
