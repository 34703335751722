.footer {
  @include smooth-gradient(to top, var(--accent--raw));
  position: sticky;
  z-index: 10;
  bottom: 0;

  left: 0;
  right: 0;
  height: 14rem;
  padding: var(--gutter);

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;

  &__links {
    @include no-select;
    @include font('label');

    display: flex;
    pointer-events: auto;
    gap: calc(var(--gutter) * 3);
    text-transform: lowercase;

    @include screen('mobile') {
      display: none;
    }

    a {
      @include hitbox(1rem);
    }

    a.is-active,
    a:hover {
      text-decoration-color: currentColor;
    }
  }
}

.footer-secondary {
  background: var(--accent);
  padding: var(--gutter);

  &__text {
    max-width: col(3);
    margin-bottom: calc(var(--gutter) * 6);
  }

  &__partners {
    ul {
      margin-top: var(--gutter--small);
      display: flex;
      gap: var(--gutter);
    }

    img {
      height: calc(var(--gutter) * 3);
      width: auto;
    }
  }

  .label {
    color: currentColor;
  }

  &__links {
    @include no-select;
    @include font('label');
    margin-top: var(--gutter);
    padding-top: var(--gutter);
    gap: var(--gutter);
    border-top: 1px solid;
    display: flex;
    justify-content: space-between;
    text-transform: lowercase;

    @include screen('mobile') {
      flex-direction: column;
      gap: 0.25em;
    }

    a:hover {
      text-decoration-color: currentColor;
    }
  }
}
