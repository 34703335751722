.event {
  @include easing(var(--fast), background-color);

  --cols: 6;
  --cards-width: #{col(2)};
  width: col(2);
  height: 24rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include screen('mobile') {
    --cols: 1;
    --cards-width: 100% !important;
    width: 100% !important;

    height: 20rem;
  }

  &:hover:not(:has(.tag:hover)) {
    background: var(--accent);
  }

  .card__footer {
    @include font('heading--medium');
    line-height: 1.1;
    padding-top: var(--gutter);
    padding-left: var(--gutter);

    order: -1;
    display: flex;
    flex-direction: column;

    @include screen('mobile') {
      @include font('heading--large');
    }

    time {
      text-transform: uppercase;
    }

    time:not(:first-child)::before {
      content: '↳\2009';
    }
  }

  .card__content {
    padding-bottom: var(--gutter);
  }

  .tags {
    margin-top: var(--gutter--small);
  }

  .card__header .card__title {
    @include font('heading--small');
    max-height: calc(var(--line-height--heading--small) * 2em);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card__header .label {
    white-space: nowrap;
    font-size: small;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
