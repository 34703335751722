@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';

$show-grid: false;
$max-width: 1920px;
$breakpoints: (
  'large': ($max-width, 9999px),
  'medium': (1080px, $max-width),
  'small': (768px, 1080px),
  'mobile': (0px, 768px)
);

@mixin screen ($key) {
  $breakpoint: map-get($breakpoints, $key);
  $min: nth($breakpoint, 1);
  $max: nth($breakpoint, 2);
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin screens ($keys) {
  @each $key in $keys {
    @include screen ($key) {
      @content;
    }
  }
}

:root {
  --scrollbar-width: 0px; // Set in JS
  --viewport-width: calc(100vw - var(--scrollbar-width, 0));

  --black: #000000;
  --white: #FFFFFF;
  --dim--fill: #E4E4E4;
  --dim--text: #CACACA;

  --cols: 6;
  --shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  --border: 1px solid var(--dim--fill);

  --fast: 200ms;
  --slow: 3000ms;

  --gutter: 2rem;
  --gutter--small: 1rem;

  --round: 2rem;
  --round--small: 1.2rem; // Used for nested round corners

  --font-size: 1.7rem;
  --line-height: 1.33;
  --font-weight: 300;

  --font-size--tag: 1.4rem;
  --line-height--tag: 1.0rem;
  --font-weight--tag: 400;

  --font-size--label: 1.6rem;
  --line-height--label: 1.3;
  --font-weight--label: 400;

  --font-size--heading--small: 2.0rem;
  --line-height--heading--small: 1.2;
  --font-weight--heading--small: 400;

  --font-size--heading--medium: 3.6rem;
  --line-height--heading--medium: 1.2;
  --font-weight--heading--medium: 400;

  --font-size--heading--large: 5.8rem;
  --line-height--heading--large: 1.0;
  --font-weight--heading--large: 400;
  --text-decoration-thickness--heading--large: 3px;

  @include screen('large') { --cols: 6; }
  @include screen('medium') { --cols: 6; }
  @include screen('small') { --cols: 3; }

  @include screen('mobile') {
    --cols: 2;
    --gutter: 1.5rem;
    --gutter--small: 1.5rem;
    --font-size--label: 1.5rem;
    --font-size--heading--small: 1.7rem;
    --font-size--heading--medium: 2.0rem;
    --font-size--heading--large: 3rem;
  }
}

@function col ($len: 1, $inside: true) {
  @if $inside {
    @return calc(Min(
      var(--viewport-width) - var(--gutter) * 2,
      #{$len} * ((Min(var(--viewport-width), #{$max-width}) - var(--gutter)) / var(--cols)) - var(--gutter)
    ));
  } @else {
    @return calc(Min(
      var(--viewport-width),
      #{$len} * ((Min(var(--viewport-width), #{$max-width}) - var(--gutter)) / var(--cols))
    ));
  }
}

@keyframes fade-in {
  0% { opacity: 0; pointer-events: none; }
  100% { opacity: 1; pointer-events: auto; }
}

@mixin fade-in ($delay: 0ms) {
  animation: fade-in var(--fast) $delay cubic-bezier(.04,-0.01,.22,1) both;
}

@mixin font ($suffix: '') {
  font-size: var(--font-size--#{$suffix}, var(--font-size));
  line-height: var(--line-height--#{$suffix}, var(--line-height));
  font-weight: var(--font-weight--#{$suffix}, var(--font-weight));
  text-decoration-thickness: var(--text-decoration-thickness--#{$suffix}, 1px);
}

@mixin full-width {
  position: relative;
  width: calc(Min(var(--viewport-width), #{$max-width}) - var(--gutter) * 2);
  left: 50%;
  right: 50%;
  margin-left: calc(var(--gutter) - Min(var(--viewport-width) / 2, #{$max-width / 2}));
  margin-right: calc(var(--gutter) - Min(var(--viewport-width) / 2, #{$max-width / 2}));
}

@mixin underline ($hoverable: true) {
  @include easing(var(--fast), text-decoration-color);
  text-decoration: underline;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 1px;

  @if $hoverable {
    text-decoration-color: transparent;
    &:hover {
      text-decoration-color: currentColor;
    }
  }
}

@include screens(('large', 'medium', 'small')) {
  .mobile { display: none !important; }
}

@import 'sass/base';
@import 'sass/prose';

@import 'components/Article';
@import 'components/Card';
@import 'components/Cards';
@import 'components/Filters';
@import 'components/Footer';
@import 'components/Location';
@import 'components/Logo';
@import 'components/Menu';
@import 'components/PagesIndex';
@import 'components/Search';
@import 'components/Tags';

@import 'components/cards/Commentary';
@import 'components/cards/Contribution';
@import 'components/cards/Event';
@import 'components/cards/Movie';
@import 'components/cards/Person';

@import 'templates/contribution';
@import 'templates/event';
@import 'templates/events';
@import 'templates/home';
@import 'templates/movie';
@import 'templates/movies';
@import 'templates/person';
@import 'templates/search';

@import 'templates/debug/components';
@import 'templates/debug/logo';
@import 'templates/debug/suivi-des-films';

@if $show-grid {
  main::after {
    @include inset(0, fixed);
    z-index: 999;
    content: '';
    opacity: 0.1;
    pointer-events: none;
    max-width: $max-width;
    margin: 0 auto;
    background-size: col(1, false);
    background-image: linear-gradient(to right, transparent var(--gutter), red 0);
  }
}
