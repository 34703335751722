.prose > {
  h3,
  h4,
  h5,
  h6,
  ul, ul ul,
  details,
  p {
    margin-bottom: var(--gutter);
  }

  .label:not(:first-child) {
    margin-top: var(--gutter);
  }

  ul li,
  .card, .label {
    margin-bottom: var(--gutter--small);
  }

  .block, figure, blockquote {
    margin:
      calc(2 * var(--gutter))
      calc(-1 * var(--gutter));

    @include screens(('small', 'mobile')) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  h3 { @include font('heading--medium'); }
  h4 { @include font('heading--small'); }
  h5 {
    @include font('label');
    text-transform: uppercase;
    color: var(--dim--text);
  }
  h6 { @include font('label'); }

  figure {
    &[data-float] {
      width: 50%;
      margin-top: 0;
      margin-bottom: 0;

      @include screen('mobile') {
        float: none !important;
        width: unset;
      }

      & + p {
        // Do not wrap text below floated image
        overflow: hidden;
      }

      & + *:not(:is(p)) {
        clear: both;
      }

      &[data-float='left'] {
        float: left;
        margin-right: var(--gutter);
      }

      &[data-float='right'] {
        float: right;
        margin-left: var(--gutter);
      }
    }

    .iframe-container {
      aspect-ratio: 16/9;
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    figcaption {
      @include font('label');
      color: var(--dim--text);
      text-align: center;
      margin: var(--gutter--small) var(--gutter);
      padding: 0 var(--gutter);
    }
  }

  blockquote {
    position: relative;
    padding: var(--gutter);
    background: var(--dim--fill);
    border-radius: var(--round);
    font-style: italic;

    footer {
      font-style: normal;
      margin-top: var(--gutter);
      text-align: right;
    }
  }

  .block-movies,
  .block-contribution {
    @include full-width;
  }

  ul, ul ul {
    list-style: disc outside;
    padding-left: 1em;
  }
}
