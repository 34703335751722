body[data-template='movie'] {
  .article__cover {
    margin: 0 calc(var(--gutter) * -1);
    img { border-radius: unset; }
    iframe.vimeo {
      width: 100%;
      height: 100%;
      background: var(--black);
    }
  }

  .article__header {
    margin-left: col(1);
    padding-left: var(--gutter);
    max-width: col(4);

    @include screens(('small', 'mobile')) {
      margin-left: 0;
      padding-left: 0;
    }
  }

  .article__content .article__aside ul {
    @include font('label');

    @include screens(('large', 'medium')) {
      margin-bottom: calc(2 * var(--gutter));
    }

    li {
      display: flex;
      align-items: baseline;
      gap: var(--gutter);
      margin-bottom: var(--gutter--small);
      padding-right: var(--gutter);

      &:has(.tags.is-empty) {
        display: none;
      }

      @include screens(('small', 'mobile')) {
        padding-right: unset;
        padding-bottom: var(--gutter);
        margin-bottom: var(--gutter);
        border-bottom: var(--border);
      }
    }

    li::before {
      flex: none;
      width: col(1);
      text-align: right;

      @include screens(('small', 'mobile')) {
        margin-right: auto;
        text-align: left;
      }
    }

    li .tags {
      max-width: col(1);
      flex-direction: column;
      align-items: flex-start;

      @include screen('small') {
        max-width: unset;
        flex-direction: row;
        justify-content: flex-end;
      }

      @include screens(('small', 'mobile')) {
        margin-left: auto;
        align-items: flex-end;
      }
    }
  }

  .article__content .article__text {
    margin-left: unset;
  }

  .movie__poster {
    margin: unset;
    margin-bottom: var(--gutter);
  }

  .article__footer {
    @include full-width;

    & > .card {
      margin-bottom: var(--gutter);
    }
  }
}
