body[data-template='event'] {
  .article__subtitle {
    time + .tags {
      margin-top: var(--gutter--small);
    }
  }

  .block-movies {
    width: unset;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-right: unset;
  }

  .location {
    margin-bottom: var(--gutter);
  }
}
