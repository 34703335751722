body[data-template='events'] {
  h2 {
    margin: calc(var(--gutter) * 2) 0;
  }

  .events {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--gutter);
  }

  .event[data-finished] {
    footer {
      @include easing(var(--fast), color);
    }

    .card__title {
      // Fix Safari glitch with text-decoration-color transition
      transition: none;
    }

    &:not(:hover) footer {
      color: var(--dim--text);
    }
  }

}
