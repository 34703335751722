.tags {
  @include invisible-scroll;
  width: 100%;
  display: flex;
  gap: var(--gutter--small);

  &.is-empty {
    display: none;
  }

  &[data-paginate] {
    flex-wrap: wrap;
  }

  .tag {
    flex: none;
  }

  button[data-action=more] {
    @include easing(var(--fast), (color, background-color));
    @include reset-button;
    cursor: pointer;

    border-radius: 100%;
    width: 2.2rem;
    height: 2.2rem;

    background: var(--dim--fill);

    &:hover {
      color: var(--white);
      background: var(--accent);
    }

    html:not(.js) & {
      display: none;
    }
  }
}
