.cards {
  @include no-select;
  --cols: 3;
  --cards-width: calc(100% / var(--cols) - (var(--gutter) / var(--cols)) * (var(--cols) - 1));
  --button-offset: calc(-1 * var(--gutter--small));

  position: relative;

  &[data-size='small'] {
    --cols: 3;

    @include screen('small') { --cols: 2; }
    @include screen('mobile') { --cols: 1; }
  }

  &[data-size='medium'] { --cols: 2; }
  &[data-size='large'] { --cols: 1; }

  & > .label {
    margin-bottom: var(--gutter--small);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: var(--gutter);
  }

  .card {
    flex: none;
    width: var(--cards-width) !important;
    scroll-snap-align: start end;

    @include screen('mobile') {
      scroll-snap-align: start;
    }
  }

  &:not([data-scrollable]) button {
    display: none;
  }

  &[data-scrollable] &__content {
    @include invisible-scroll;
    scroll-snap-type: x mandatory;
    flex-flow: row nowrap;
  }

  &[data-scrollable] button {
    // Avoid flashing button when immediately removed in JS
    @include fade-in(200ms);
    @include easing(var(--fast), (transform, opacity));
    @include reset-button;
    @include hitbox(1rem);
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 100%;
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.85rem;
    text-align: center;

    cursor: pointer;
    color: transparent;
    background: var(--white);
    box-shadow: var(--shadow);

    background-image: var(--svg);
    background-size: 1.8rem 1.8rem;
    background-position: center center;
    background-repeat: no-repeat;

    &.is-disabled {
      opacity: 0.4 !important;
    }

    &:not(.is-disabled):hover {
      transform: translateY(-50%) scale(1.1);
    }

    &:not(.is-disabled):active {
      transform: translateY(-50%) scale(0.9);
    }

    &[data-action=previous] {
      --svg: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" id="icon-angle-left" viewBox="0 0 16 16" width="100%25" height="100%25"%3E%3Cpath d="M10 13.4L4.6 8 10 2.6 11.4 4l-4 4 4 4z"%3E%3C/path%3E%3C/svg%3E');
      left: var(--button-offset);
    }

    &[data-action=next] {
      --svg: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" id="icon-angle-right" viewBox="0 0 16 16" width="100%25" height="100%25"%3E%3Cpath d="M6 13.4L4.6 12l4-4-4-4L6 2.6 11.4 8z"%3E%3C/path%3E%3C/svg%3E');
      right: var(--button-offset);
    }
  }
}
