.menu {
  @include no-select;
  @include font('label');
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  gap: var(--gutter);
  padding: var(--gutter);
  background: var(--white);

  .logo {
    position: relative;
    z-index: 1;
    height: 3.9rem;
    margin-block: -0.5rem;
  }

  #toggleMenu, label[for=toggleMenu] {
    display: none;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: calc(var(--gutter) * 3);
    text-transform: lowercase;
  }

  &__link a {
    @include hitbox(1rem);
  }

  &__link.is-active a {
    text-decoration-color: currentColor;
  }

  &__link--search {
    margin-top: -2px;
  }

  label {
    @include hitbox(1rem);
    cursor: pointer;
  }

  // Mobile menu
  @include screen('mobile') {
    $menu-height: 5.5rem;

    body:has(& #toggleMenu:checked) {
      overflow: hidden;
    }

    label[for=toggleMenu] {
      @include easing(var(--fast), transform);
      margin-left: auto;
      display: block;
      -webkit-tap-highlight-color: transparent;
    }

    #toggleMenu:checked ~ label[for=toggleMenu] {
      transform: rotate(90deg);
    }

    #toggleMenu:not(:checked) ~ &__content {
      opacity: 0;
      pointer-events: none;
    }

    &__content {
      @include no-select;
      @include inset(0, fixed);
      @include easing(var(--fast), opacity);
      @include invisible-scroll(y);
      @include smooth-gradient(to top, var(--accent--raw), var(--white));

      display: block;
      pointer-events: auto;
      opacity: 1;

      margin-top: $menu-height;
      padding: var(--gutter);
    }

    &__links {
      @include font('heading--medium');
      gap: var(--gutter);
      flex-direction: column;
      align-items: flex-start;

      &:first-child { margin-top: calc(var(--gutter) * 3); }
      &:last-child { margin-bottom: calc(var(--gutter) * 3); }
    }

    .search {
      margin: calc(var(--gutter) * 2) 0;
    }

    &__link, &__link a {
      display: block;
      width: 100%;
    }

    &__link--search {
      display: none;
    }
  }
}
