.pages-index {
  width: 100%;

  td {
    padding: var(--gutter);
    overflow: hidden;
    text-overflow: ellipsis;

    @include screens(('small', 'mobile')) {
      width: 100% !important;
      display: block;
      padding: 0;
      margin-bottom: var(--gutter);

      &[data-thead]::before {
        content: attr('data-thead');
      }

      &:first-child {
        padding-top: var(--gutter);
      }
    }

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }

  thead {
    @include no-select;
    @include font('label');
    text-transform: uppercase;
    color: var(--dim--text);

    @include screens(('small', 'mobile')) {
      display: none;
    }

    td[data-sorted='ASC']::after { content: '\00a0↑'; }
    td[data-sorted='DESC']::after { content: '\00a0↓'; }
  }

  tbody {
    td:first-child {
      @include font('heading--small');
    }

    tr {
      border-top: var(--border);
    }
  }

  td.title {
    width: 50%;
  }

  td.date {
    @include font('label');
    text-transform: uppercase;
  }

  .location {
    background: unset;
    padding: unset;
  }
}
