.filters {
  @include no-select;
  input[type=radio], input[type=checkbox] {
    display: none;
  }

  &__buttons {
    display: flex;
    gap: var(--gutter);
    align-items: center;
    white-space: nowrap;

    @include screen('mobile') {
      @include invisible-scroll;
      margin-bottom: var(--gutter--small);
    }
  }

  &__definitions {
    display: flex;
    align-items: center;
    gap: var(--gutter--small);
  }

  &__definition {
    display: flex;

    &[data-count='0'] {
      display: none;
    }
  }

  &__definition label {
    @include easing(var(--fast), color);
    @include font('label');
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    text-transform: lowercase;
    color: var(--dim--text);

    &.is-open {
      color: var(--black);
      &::after {
        transform-origin: center 43%;
        transform: scale(1, -1);
      }
    }

    &::after {
      content: '▾';
      display: inline-block;
      margin-left: var(--gutter--small);
    }

    &:hover {
      color: var(--black);
    }
  }

  &__filter {
    @include easing(var(--fast), color);

    position: relative;
    display: flex;
    align-items: center;

    color: var(--dim--text);

    @include screen('mobile') {
      display: none;
    }

    html:not(.js) & {
      display: none;
    }

    &:has(> input[type=search]:not(:placeholder-shown)) {
      color: var(--accent);
    }

    &:focus-within {
      color: var(--black);
    }

    label {
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      left: var(--gutter--small);
      margin-top: -1px;

      svg {
        fill: currentColor;
        height: 14px;
        width: auto;
      }
    }

    input[type=search] {
      @include reset-button;
      @include easing(var(--fast), opacity);
      @include font('label');

      border: var(--border);
      border-color: currentColor;
      border-radius: var(--round);

      padding: .7rem 1rem .4rem 1rem;
      padding-left: calc(var(--gutter) + var(--gutter--small));

      &::placeholder {
        text-transform: lowercase;
        color: var(--dim--text);
      }
    }

    &:not(:hover) input[type=search]:not(:focus):placeholder-shown {
      opacity: 0;
    }
  }

  // The CSS used to toggle .tags visibility is output via PHP
  &__tags .tag {
    --color: var(--black);
    --background: var(--dim--fill);
  }
}
