body[data-template='search'] {
  header {
    @include no-select;
  }

  header .search-categories {
    display: flex;
    gap: var(--gutter);

    li {
      @include easing(var(--fast), color);
      color: var(--dim--text);

      a {
        text-decoration: none;
      }

      &.is-active .label,
      &:hover .label {
        color: var(--accent);
      }
    }
  }

  header h2 {
    @include font('heading--medium');
    margin: var(--gutter--small) 0;
    text-transform: uppercase;

    label {
      @include underline;
      cursor: pointer;
      color: var(--accent);
    }
  }

  header .search-categories + .filters {
    margin-top: var(--gutter--small);
  }
}

